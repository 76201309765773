import { DataGrid, GridColumns } from '@material-ui/data-grid';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputText } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';

export const Log: React.FC = () => {
  const { register, formState, logs } = useDevolucaoSaidaNFEForn();

  const tabColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data de Envio',
      width: 180,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'cod_status',
      headerName: 'Status',
      width: 110,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'motivo',
      headerName: 'Menssagem',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'recibo',
      headerName: 'Recibo',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'protocolo',
      headerName: 'Protocolo',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  return (
    <>
      <Separator labelText="Log" sidePadding="0 0" />
      <Row>
        <Col sm={12}>
          <InputText
            label="Chave NF-e"
            maxLength={50}
            placeholder="Informe a chave"
            name="chave_nfe"
            register={register}
            disabled={false}
            isError={!!formState.errors.chave_nfe}
          />
        </Col>
        <Col sm={12} className="mt-4">
          <DataGrid
            rows={logs}
            columns={tabColumns}
            pageSize={5}
            columnBuffer={8}
            autoHeight
            disableColumnFilter
            disableColumnSelector
            disableSelectionOnClick
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar colunas',
            }}
          />
        </Col>
      </Row>
    </>
  );
};
