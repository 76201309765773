import React from 'react';
import Separator from '~/components/Separator';
import { CardCritica } from '../../components/CardCritica';
import { useDevolucaoSaidaNFEForn } from '../../DevolucaoSaidaNFEFornContext';
import { Container } from './styles';

export const Critica: React.FC = () => {
  const { criticas } = useDevolucaoSaidaNFEForn();

  return (
    <Container>
      <Separator labelText="Inconsistências" />

      {criticas.map((critica) => {
        return (
          <CardCritica
            key={critica.title}
            type={critica.type}
            title={critica.title}
            message={critica.message}
            show={critica.show}
            calculatedValue={critica.calculatedValue}
            fieldName={critica.fieldName}
          />
        );
      })}
    </Container>
  );
};
