import styled from 'styled-components';

export const Container = styled.div`
  .show-action {
    text-align: right;
    display: block;
    cursor: pointer;
    color: #56069e;
    font-size: 1rem;
    &:hover {
      opacity: 0.8;
    }
  }
`;
