import styled from 'styled-components';

export const Button = styled.button`
  float: right;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;

  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  &:focus {
    outline: 0;
  }
  svg {
    color: green !important;
  }
`;

export const ButtonItens = styled.button`
  display: flex;
  background: none;
  border: none;
  gap: 5px;
  align-items: center;
  height: 40px;
  padding: 10px;
  margin-top: 10px;
  svg {
    font-size: 16pt;
  }
  span {
    color: rgb(107, 119, 140);
  }
  &:hover {
    border: #4c9aff;
  }
`;
